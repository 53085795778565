import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { OidcUserManager } from "../../helpers/OidcSettings";
import Pagination from "@material-ui/lab/Pagination";
import Button from "@material-ui/core/Button";
// import Icon from "@material-ui/core/Icon";
import gray from "@material-ui/core/colors/grey";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/uk";
import axios from "axios";

// const extractFilename = (path) => {
//     const pathArray = path.split("/");
//     const lastIndex = pathArray.length - 1;
//     return pathArray[lastIndex];
//  };

const styles = theme => ({
    root: {
        flexGrow: 1,        
        padding: theme.spacing(1),
    },
    progress: {
        height: 2,
        position: "absolute",
        top: 0,
        left: 0,
        right:0,
      },  
      linearColorPrimary: {
        backgroundColor: "#abdcda",
      },
      linearBarColorPrimary: {
        backgroundColor: "#d20000",
      },
      topbanner: {
        maxWidth: "100%",
        height: "auto",
    },
    paper: {
        padding: theme.spacing(2),
    },
    info: {
        padding: theme.spacing(1),
        height: "auto",
        [theme.breakpoints.down("sm")]: {
            height: 100,
          },
    },
    divider: {        
        marginBottom:theme.spacing(2),
    },
    customer: {
        textTransform: "capitalize",
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    media: {
        height: 183,
        // backgroundSize: "contain",
    },
    value: {
        fontWeight: "bold",
    },
    table: {
        minWidth: 500,
    },
    bold: {
        fontWeight: "500",
    },
    total: {
        fontWeight: "500",
        backgroundColor: gray[200],
    },
    step: {
        marginRight: theme.spacing(1),        
      },  
    fixcell: {
        backgroundColor: gray[100],
    },
    article: {
        width: "auto",
        [theme.breakpoints.down("sm")]: {
            whiteSpace: "initial",
            maxWidth: "20rem",
            overflowX: "auto",
          },          
    },
});

class News extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            keys: [],
            isLoading: false,
            query: "",
            page: 1,
            pagesize: 10,
            count: 10,
        };

        moment.locale("uk");
    }

    componentDidMount() {
        window.scrollTo({top: -200, behavior: "smooth"});
        const {query, page, pagesize} = this.state;
        this.loadNews(query, page, pagesize);        
    }

    // componentWillUnmount() {        
    // }

    handleChangePage = async (event, value) => {
        this.setState({page: value});
        const {query, pagesize} = this.state;
        
        await this.loadNews(query, value, pagesize);
      };

    loadNews = async (query, page, pagesize) => {        
        const self = this;
        const userManager = await OidcUserManager();

        userManager.getUser().then(user => {
            let token = null;

            if (user != null) {
                token =  `Bearer ${user.access_token}`;
            }

            this.setState({isLoading: true});
            const URL =  `/api/v1.0/news/${query.length > 0 ? query : " "}/${page}/${pagesize}`;

            axios.get(URL,  
                { headers: { Authorization: token } }
            )                
            .then(function(response){
                const keys = [];
                if(response.data){
                    let count = parseInt(response.data.TotalCount / response.data.PageSize);
                    if(response.data.TotalCount % response.data.PageSize > 0){
                        count +=1;
                    }
                    self.setState({ data: {...response.data}, isLoading: false, keys: keys, count: count });                
                    console.log("News data", response.data); 
                }                
            })
            .catch((error) => {        
                this.setState({isLoading: false});
                console.log(error);
            });                
            
        });
    };


    render() {
        const { classes, t } = this.props; 
        const { data, isLoading, count, page } = this.state;

        return (
            <div className={classes.root}>
                { isLoading && 
                    <LinearProgress className={classes.progress} 
                    classes={{
                        colorPrimary: classes.linearColorPrimary,
                        barColorPrimary: classes.linearBarColorPrimary,
                    }}
                />
                }

                <Grid container direction="row" spacing={2} justify="flex-start" alignItems="center" >
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={0}>
                            <Typography variant="h5">
                                {t("news.title")}
                            </Typography>                            
                        </Paper>
                    </Grid>
                </Grid>
                {
                    data && <React.Fragment> 
                    <Grid container spacing={2} direction="row"  > 
                        { data.Contents.map((content) => (
                        <Grid key={content.Id} item xs={12}>                                                                           
                            <Paper className={classes.paper} elevation={8}>
                                <Typography variant="h5" gutterBottom >
                                    {content.Name}    
                                </Typography>

                                { content.LocalValues[0].Annonce ? 
                                    <React.Fragment>                                        
                                        { content.TopBanner.length > 0 &&                                                                                             
                                        <picture className={classes.topbanner}>
                                            {/* large */}
                                            <source media="(min-width: 1134px)" srcSet={content.TopBanner} />
                                            {/* medium */}
                                            <source media="(max-width: 1133px)" srcSet={content.TopBanner.replace(content.TopBannerFile, `/xl/${content.TopBannerFile}`)} />
                                            {/* small */}
                                            <source srcSet={content.TopBanner.replace(content.TopBannerFile, `/md/${content.TopBannerFile}`)} />
                                            <img className={classes.topbanner} src={content.TopBanner} alt={content.Name} />
                                        </picture>
                                        }                                        
                                        <Typography variant="subtitle1" style={{marginTop: "8px" }} gutterBottom>
                                            {content.LocalValues[0].Annonce}
                                        </Typography>
                                        
                                        <Typography gutterBottom>
                                            <Button variant="outlined" href={`/page/${content.Key}`}>Read more...</Button>
                                        </Typography>

                                    </React.Fragment> : 
                                    <React.Fragment>
                                        <article className={classes.article}                                    
                                            dangerouslySetInnerHTML={{__html: content.LocalValues[0].Value.replace(/(<? *script)/gi, "illegalscript")}} >
                                        </article>
                                    </React.Fragment> 
                                }                                
                            </Paper>
                        </Grid>
                        ))
                        }                        
                    </Grid>
                    <Grid container direction="row" justify="center" alignItems="center">
                        <Grid item >
                            <Box mt={4}>
                                <Pagination count={count} page={page} shape="rounded" onChange={this.handleChangePage} />
                            </Box>
                        </Grid>
                    </Grid>
                    </React.Fragment>                                    
                }
            </div>            
        );
    }
}

export default  withTranslation()(withWidth() (withStyles(styles)((News))));

