import React, { Component } from "react"
import {withRouter} from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionOrderCreators } from "../../store/Order";
import {withStyles} from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import { OidcUserManager } from "../../helpers/OidcSettings";
import Grid from "@material-ui/core/Grid";
import axios from "axios";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import DoneIcon from "@material-ui/icons/Done";

import Button from "@material-ui/core/Button";


const styles = theme => ({
    root: {
        flexGrow: 1,        
        padding: theme.spacing(2, 1),
    },
    progress: {
        height: 2,
        position: "absolute",
        top: 0,
        left: 0,
        right:0,
    },  
    linearColorPrimary: {
        backgroundColor: "#abdcda",
    },
    linearBarColorPrimary: {
        backgroundColor: "#d20000",
    },
    paper: {
        padding: theme.spacing(4,1),
    },    
    line: {
        // padding: theme.spacing(1),
        marginTop: theme.spacing(2),
    },                
    done: {
        color: theme.palette.success.main,
    },      
    goto: {
          marginRight: theme.spacing(1),
    }
});

class Thanks extends Component {
    constructor(props){
        super(props);
        this.state = {
            isLoading: false,
            id: this.props.match.params.id,
            data: null,
            showSuccess: true,
            token: null,
        }; 
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if(nextProps.match.params.id !== prevState.id){
          return { id: nextProps.match.params.key};
        }
        else return null;
    }

    componentDidUpdate(prevProps, prevState) {        
        if(this.state.id !==  prevState.id ){            
            this.loadOrder(this.state.id);
        }        
    }

    componentDidMount() {
        window.scrollTo({top: 0, behavior: "smooth"});
        this.loadOrder(this.state.id);
    }

    loadOrder = async (id) => {
        
        const self = this;

        const userManager = await OidcUserManager();

        userManager.getUser().then(user => {            
            let token = null;

            if (user !== null) {
                if(user.access_token !== null ){
                    token =  `Bearer ${user.access_token}`;
                }                
            }        
            // token =  `Bearer ${user.access_token}`;                
            
            this.setState({isLoading: true});
            const URL = `/api/v1.0/order/confirmed/${id}`;
                        
            axios.get(URL,  
                { headers: { Authorization: token } }
            )                
            .then(function(response){                                                    
                self.setState({ data: response.data, isLoading: false });                
                // console.log("GET ORDER", response.data);                 
            })
            .catch((error) => {        
                this.setState({isLoading: false});
                console.log(error);
            });
        });

    };

    render() {
        const { classes, t } = this.props;
        const { isLoading, data, showSuccess, token } = this.state;        
        return (
            <div className={classes.root}>
                { isLoading && 
                    <LinearProgress className={classes.progress} 
                    classes={{
                        colorPrimary: classes.linearColorPrimary,
                        barColorPrimary: classes.linearBarColorPrimary,
                    }}
                />
                }
                { data && 
                <Grid spacing={2}  container direction="row" justify="center" alignItems="center" >
                    <Grid item xs={12} sm={8} lg={6}>
                        <Paper className={classes.paper} >
                            
                            <Typography variant= { "h5"}  align="center" gutterBottom>
                                {t("order.ThanksForOrder")}
                            </Typography>
                            
                            <Typography align="center" gutterBottom>
                                {data.DocNum}, <b>{data.Amount}</b>
                            </Typography>
                            
                            
                            
                            <Typography align="center">
                                {`${data.Customer.RegistrationCode}, ${data.RecipientFirstName} ${data.RecipientLastName} ` }
                            </Typography>

                            <Typography align="center" gutterBottom>
                                <small>{`${data.Customer.Phone}, ${data.Customer.Email} ` }</small> 
                            </Typography>
                                                                                    
                                {
                                showSuccess && 
                                    <Grid container direction="row" justify="center" alignItems="center" >
                                        <Grid item xs={12} style={{textAlign: "center"}}> 
                                            <DoneIcon className={classes.done} fontSize="large" />
                                        </Grid>

                                        { token ?
                                        <React.Fragment>
                                        <Grid item style={{textAlign: "center"}}> 
                                            <Button className={classes.goto} color="primary" onClick={() => {this.props.history.push("/order");}} >
                                                {t("pay.GoToOrder")}
                                            </Button>                                        
                                        </Grid> 
                                        <Grid item style={{textAlign: "center"}}> 
                                            <Button className={classes.goto} color="primary" onClick={() => {this.props.history.push("/orders");}} >
                                                {t("pay.GoToOrders")}
                                            </Button>
                                        </Grid>
                                        </React.Fragment> :

                                        <Grid item style={{textAlign: "center"}}> 
                                            <Button className={classes.goto} color="primary" onClick={() => {this.props.history.push("/");}} >
                                                {t("pay.GoHome")}
                                            </Button>
                                            </Grid>
                                        }

                                    </Grid>                                   
                                }                            

                        </Paper>                        
                    </Grid>
                </Grid>
                }

            </div>
        )
    }
}

export default withTranslation()(withWidth() (withRouter( withStyles(styles)(
    connect(    
        state => state.orderRequest,
        dispatch => bindActionCreators(actionOrderCreators, dispatch)
    )(Thanks)
))));