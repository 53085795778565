import React from "react";
// import { withStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { actionCreators } from "../store/Counter";

const Counter = props => (
  <div>
    

    <h1>Counter</h1>

    <p>This is a simple example of a React component.</p>

    <p>Current count: <strong>{props.count}</strong></p>

    {/* <button onClick={props.increment}>Increment</button> */}
    
    <Button variant="contained" color="secondary" onClick={props.increment}  >
        + Increment
    </Button>
  </div>
);

export default connect(
  state => state.counter,
  dispatch => bindActionCreators(actionCreators, dispatch)
)(Counter);
