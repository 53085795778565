import { UserManager } from "oidc-client";

export async function OidcSettings()
{
    // console.log(`${process.env.REACT_APP_CDN}Pictures/`);

    let oidcSettings = JSON.parse(sessionStorage.getItem("oidcSettings"));
    
    if(oidcSettings !== null)
    {
        return oidcSettings;
    }
    const url = "/api/applayout/authenticationconfiguration";
    const response = await fetch(url);
    const data = await response.json();    
    
    // console.log(data);
    
    oidcSettings = {
        authority: data.IdentityServerBaseUri,  
        client_id: data.OidcClientId,     
        redirect_uri: data.HostRedirectUri,
        post_logout_redirect_uri: data.HostBaseUri,  
        silent_redirect_uri: data.HostRedirectUri,// data.SilentRedirectUri,    
        response_type: data.OidcResponseType,
        scope: data.Scopes.join(" "),      
        automaticSilentRenew: true,
        monitorSession : true,    
        // filterProtocolClaims: true,
        loadUserInfo: true,
        // accessTokenExpiringNotificationTime: 60,
        // silentRequestTimeout: 100,    
    };

    sessionStorage.setItem("oidcSettings", JSON.stringify(oidcSettings));
    return oidcSettings
}

export async function OidcUserManager()
{    

    const setts = await OidcSettings(); 

    const userManager = new UserManager(setts);

    userManager.events.addAccessTokenExpiring(function(){
        console.log("token expiring...");
        // userManager.signinPopup();
        // userManager.signinSilent();
        userManager.signinRedirect(); 
    });
    
    // eslint-disable-next-line no-unused-vars
    userManager.events.addUserLoaded(args => {    
        console.log("addUserLoaded");
        userManager.getUser().then(user => {
            userManager._user = user; // load the new user
        });
      });

    return userManager;
}