import React from "react";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
// import PageNotFound from '../assets/images/PageNotFound';
const styles = theme => (
{
    root: {
        flexGrow: 1,
        margin: theme.spacing(4),
        padding: theme.spacing(1),
        [theme.breakpoints.down("sm")]: {
            minHeight: 300,
            margin: theme.spacing(4, 2),
        }
    },
    head: {                
    },
    paper: {
        padding: theme.spacing(8, 2),        
        transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",        
        boxShadow: "rgb(145 158 171 / 24%) 0px 0px 2px 0px, rgb(145 158 171 / 24%) 0px 16px 32px -4px",
    }
});


const NotFound = (props) => {
    const { classes } = props;

return(
    <div className={classes.root}>
    {/* <img src={PageNotFound} style={{width: 400, height: 400, display: 'block', margin: 'auto', position: 'relative' }} /> */}
        <center>
            <Paper className={classes.paper} >
                
                <Typography className={classes.head} color='error' variant="h1" >
                    404
                </Typography>
                
                <Typography variant="h5" gutterBottom >
                    Page not foud :(
                </Typography>

                <Typography gutterBottom >
                    Ooooups! Looks like you got lost.
                </Typography>
                <br />
                <p>                    
                    <Button variant="outlined" href="/" color="secondary">
                        Go back home
                    </Button>
                </p>        
            </Paper>
            
        </center>
    </div>
)};

NotFound.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withStyles(styles) (NotFound);