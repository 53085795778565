import React from "react";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import {withStyles} from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import axios from "axios";
import Avatar from "@material-ui/core/Avatar";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import StarIcon from "@material-ui/icons/StarBorder";

const styles = theme => ({
    root: {
        flexGrow: 1,
        // paddingTop: theme.spacing(1),
    },
    heroUnit: {
        backgroundColor: theme.palette.background.paper,
        //backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
    },
    heroContent: {        
        maxWidth: 650,
        margin: "0 auto",
        padding: `${theme.spacing(1) * 8}px ${theme.spacing(1)}px`,
    },
    heroButtons: {
        marginTop: theme.spacing(1) * 6,
    },
    avatar: {
        width: 124,
        height: 124,
        boxShadow: "0 2px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
    },
    layout: {
        width: "auto",

        marginTop: theme.spacing(1) * 6,
        marginLeft: theme.spacing(1) * 3,
        marginRight: theme.spacing(1) * 3,
        [theme.breakpoints.up(1000 + theme.spacing(1) * 3 * 2)]: {
          width: 1000,
          marginLeft: "auto",
          marginRight: "auto",
        },
      },
      cardHeader: {
        backgroundColor: theme.palette.grey[200],
      },
      cardPricing: {
        display: "flex",
        justifyContent: "center",
        alignItems: "baseline",
        marginBottom: theme.spacing(1) * 2,
      },
      cardActions: {
        [theme.breakpoints.up("sm")]: {
          paddingBottom: theme.spacing(1) * 2,
        },
      },
});

const tiers = [
    {
      title: "Free",
      price: "0",
      description: ["10 users included", "2 GB of storage", "Help center access", "Email support"],
      buttonText: "Sign up for free",
      buttonVariant: "outlined",
      color: "primary",
    },
    {
      title: "Professional",
      subheader: "Most popular",
      price: "15",
      description: [
        "20 users included",
        "10 GB of storage",
        "Help center access",
        "Priority email support",
      ],
      buttonText: "Get started",
      buttonVariant: "contained",
      color: "secondary",
    },
    {
      title: "Enterprise",
      price: "30",
      description: [
        "50 users included",
        "30 GB of storage",
        "Help center access",
        "Phone & email support",
      ],
      buttonText: "Contact us",
      buttonVariant: "outlined",
      color: "primary",
    },
  ];

class You extends React.Component{
    constructor(props) {        
        super(props);
        const code = this.props.match.params.code || "";
        this.state = {
            avatarPath: "https://jerelia.com/Pictures/avatars/",
            imgpath: "/assets/files/images/you",
            img: "bg1100008.jpg",
            code: code,
            textColor: "#ffffff",
            selectedConsultant: null,
        };
        if(code.length > 0){
            this.searchConsultantByCode(code);
        }
    }

    searchConsultantByCode = code =>{        
        this.setState({ selectedConsultant: null });       
        const URL = `/api/v1/jerelia/getcustomerbyregistrationcode/${code}`;        
        axios.get(URL) 
        .then((response) => {
          if(response.data){            
            this.setState({ selectedConsultant: response.data });
          }          
        })
        .catch((error) => {            
            console.log(error);                
        });
    }

    getAvatar = mentor => {
        if(mentor.Avatar){
            return this.state.avatarPath + mentor.Avatar;
        }
        else {
            if(mentor.Gender === 1){
                return  "assets/files/images/avatars/nerd.svg";
            }
            else{
                return "assets/files/images/avatars/teacher.svg";
            }
        }        
    }

    render(){
        const { classes } = this.props;
        // console.log(`${this.state.imgpath}/${this.state.code}/${this.props.width}/${this.state.img}`);

        return(
            <div className={classes.root}>                
                <div className={classes.heroUnit} style={{ backgroundImage:`url(${this.state.imgpath}/${this.state.code}/${this.props.width}/${this.state.img})` }} >
                    <div className={classes.heroContent}>
                        <Typography component="h1" variant="h2" align="center" style={{color: this.state.textColor}}  gutterBottom>
                            Tell us your story
                        </Typography>
                        
                        { this.state.selectedConsultant &&
                        <Grid container justify="center" alignItems="center">
                            <Avatar alt="Remy Sharp" sizes="96" src={this.getAvatar(this.state.selectedConsultant)} className={classes.avatar} />                            
                        </Grid>
                        }
                        
                        <Typography variant="h5" align="center" style={{color: this.state.textColor}} paragraph>
                            Something short and leading about the collection below—its contents, the creator, etc.
                            Make it short and sweet, but not too short so folks don&apos;t simply skip over it entirely.
                        </Typography>

                        <div className={classes.heroButtons}>
                            <Grid container spacing={16} justify="center">
                                <Grid item>
                                <Button variant="contained" color="secondary">
                                    Main call to action
                                </Button>
                                </Grid>
                                <Grid item>
                                <Button variant="contained" color="primary">
                                    Secondary action
                                </Button>
                                </Grid>
                            </Grid>
                        </div>
                    </div>
                </div>
                <main className={classes.layout}>
                <Grid container spacing={40} alignItems="flex-end">
                    {tiers.map(tier => (
                        // Enterprise card is full width at sm breakpoint
                        <Grid item key={tier.title} xs={12} sm={tier.title === "Enterprise" ? 12 : 6} md={4}>
                        <Card>
                            <CardHeader
                            title={tier.title}
                            subheader={tier.subheader}
                            titleTypographyProps={{ align: "center" }}
                            subheaderTypographyProps={{ align: "center" }}
                            action={tier.title === "Pro" ? <StarIcon /> : null}
                            className={classes.cardHeader}
                            />
                            <CardContent>
                            <div className={classes.cardPricing}>
                                <Typography component="h2" variant="h3" color="textPrimary">
                                ${tier.price}
                                </Typography>
                                <Typography variant="h6" color="textSecondary">
                                /mo
                                </Typography>
                            </div>
                            {tier.description.map(line => (
                                <Typography variant="subtitle1" align="center" key={line}>
                                {line}
                                </Typography>
                            ))}
                            </CardContent>
                            <CardActions className={classes.cardActions}>
                            <Button fullWidth variant={tier.buttonVariant} color={tier.color}>
                                {tier.buttonText}
                            </Button>
                            </CardActions>
                        </Card>
                        </Grid>
                    ))}
                </Grid>
                </main>





            </div>
        );
    }
}

export default withWidth()(withStyles(styles)(You));