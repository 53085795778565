import axios from "axios";
// import userManager from "../helpers/userManager";
import { OidcUserManager } from "../helpers/OidcSettings";

// import { push } from "react-router-redux";
import qs from "qs";

import * as Constants from "../helpers/Constants";
export const AUTH_START = "AUTH_START";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAIL = "AUTH_FAIL";
export const AUTH_LOGOUT = "AUTH_LOGOUT";
export const SET_AUTH_REDIRECT_PATH = "SET_AUTH_REDIRECT_PATH";

const initialState = {     
    token: null,
    userId: null,
    error: null,        
    authRedirectPath: "/",     
    isLoading: false 
};

export const actionCreators = { 
    getUserInfo : () =>  async (dispatch) => {
        const userManager = await OidcUserManager();

        userManager.getUser().then(user => {
            if(user != null){
                console.log(user);
                
                // const URL = "/api/v1.0/values";
                // const token =  `Bearer ${user.access_token}`; 
                
                // axios.get(URL, { headers: { Authorization: token } })
                // .then(response => {
                //     // If request is good...
                //     console.log(response.data);
                // })
                // .catch((error) => {
                //     console.log("error " + error);
                // });

            }
            else {
                console.log("User is null");
            }            
        });
    },

    onLogin : () =>  async (dispatch) => {        
        dispatch({ type: AUTH_START });
        const userManager = await OidcUserManager();     
        userManager.signinRedirect();
    },
    
    onLogout : () =>  async (dispatch) => {
        dispatch({ type: AUTH_START });
        const userManager = await OidcUserManager();        
        userManager.signoutRedirect();
    },

    onAuth: (email, password) => async (dispatch, getState) => {
        // const state = getState();
        dispatch({ type: AUTH_START });                               
        
        const authData = {
            client_id: Constants.clientId,
            client_secret: "secret",
            grant_type: "password",
            redirect_uri: `${Constants.clientRoot}signin-callback.html`,            
            username: email,
            password: password,
            scope: Constants.clientScope,
        };

        const url = `${Constants.stsAuthority}connect/token`;
        const headers = { "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"};
        
        axios.post(url, qs.stringify(authData), headers)
        .then(response => {
            console.log(response);           
        })
        .catch(err => {
            console.log(err);
        });
            
    }
  };

// Reducer
export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case AUTH_START:             
            return {
                ...state,
                isLoading: true
              }
        case AUTH_SUCCESS:
              return {
                ...state,
                auth: action.auth,
                isLoading: false
              }
        default:
            return state;
    }
    
    
};