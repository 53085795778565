import axios from "axios";
// import userManager from "../helpers/userManager";
import { OidcUserManager } from "../helpers/OidcSettings";

const requestFilesType = "REQUEST_FILES";
const receiveFilesType = "RECEIVE_FILES";
const changeFilesType = "CHANGE_FILES";

const initialState = { 
  treeFiles: [], 
  fullPath: [{    
    Exists: true,
    Length: -1,
    Path: "",    
    title: "Files",
    LastModified: "0001-01-01T00:00:00+00:00",
    isDirectory: true,
    children: [],
  }],
  token: "", 
  isLoading: false, 
  selectedpath: "" 
};

export const actionCreators = {
      
  // eslint-disable-next-line no-unused-vars
  requestFiles : (path) => async (dispatch, getState) => {
    if(path === undefined ) {        
        return;
    }
    // console.log("Load files ... ");   
    dispatch({ type: requestFilesType, path });
    const userManager = await OidcUserManager();

    userManager.getUser().then(function (user) {
      let token = null;   
      if(user != null){      
        token =  `Bearer ${user.access_token}`;
      }                  
      const URL = "/api/v1/files/get";      
      axios.get(URL,         
        { 
          headers: { Authorization: token },
          params: { path: path}
        }
      )
      .then(function(response){
        let fullPath = [{
          Exists: true,
          Length: -1,
          Path: "",    
          title: "Files",
          LastModified: "0001-01-01T00:00:00+00:00",
          isDirectory: true,
          children: [],
        }];

        let links = path.split("/");
        let rfmf = "";

        for (let i = 0; i < links.length; i++) {
          let link = links[i];
          if (link.length === 0)
          {
            continue;
          }
          
          rfmf = rfmf +  (i > 0 ? `/${link}` : link);

          fullPath.push({              
              Exists: true,
              Length: -1,              
              Path: rfmf,
              title: link,
              LastModified: "0001-01-01T00:00:00+00:00",
              isDirectory: true,
              children: []
            });  
        }          
        dispatch({ type: receiveFilesType, treeFiles: response.data, path: path, fullPath: fullPath, token: token });
      })
      .catch((error) => {        
            console.log(error);  
            // dispatch({ type: receiveErrorContentType, message: error.message });
          });  
      });
  },
  // eslint-disable-next-line no-unused-vars
  requestAddFolder : (path) => async (dispatch, getState) => {
    const userManager = await OidcUserManager();
    userManager.getUser().then(function (user) {
      let token = null;   
      if(user != null){      
        token =  `Bearer ${user.access_token}`;
      }      
      // console.log("path:", path);
      const URL = "/api/v1/files/post";      
      axios.post(URL,
        { path: path},
        { 
          headers: { Authorization: token }          
        }
      )
      .then(function(response){
        dispatch({ type: changeFilesType,  treeFiles: response.data });
      })
      .catch((error) => {        
        console.log(error);        
      });    
    });    
  },
  // eslint-disable-next-line no-unused-vars
  requestDelFolder : (path) => async (dispatch, getState) => {
    const userManager = await OidcUserManager();
    userManager.getUser().then(function (user) {
      let token = null;   
      if(user != null){      
        token =  `Bearer ${user.access_token}`;
      }      
      
      const URL = "/api/v1/files/delete";
      axios.post(URL,
        { path: path},
        { 
          headers: { Authorization: token }
        }
      )
      .then(function(response) {        
        dispatch({ type: changeFilesType,  treeFiles: response.data });
      })
      .catch((error) => {        
        console.log(error);        
      });    
    });    
  },
  // eslint-disable-next-line no-unused-vars
  requestRename : (path, name) => async (dispatch, getState) => {
    const userManager = await OidcUserManager();
    userManager.getUser().then(function (user) {
      let token = null;   
      if(user != null){      
        token =  `Bearer ${user.access_token}`;
      }      
      
      const URL = "/api/v1/files/put";
      axios.put(URL,
        { path: path, name: name},
        { 
          headers: { Authorization: token }
        }
      )
      .then(function(response) {        
        dispatch({ type: changeFilesType,  treeFiles: response.data });
      })
      .catch((error) => {        
        console.log(error);        
      });    
    });    
  },  
  changeFiles:  (treeFiles) => async (dispatch) => {        
    dispatch({ type: changeFilesType,  treeFiles: treeFiles });
  },
}

export const reducer = (state, action) => {
    state = state || initialState;    
    switch (action.type) {
      case requestFilesType:
        return {
          ...state,
          path: action.path,          
          isLoading: true,
        }
      case receiveFilesType:     
        return {
          ...state,
          path: action.path,
          treeFiles: action.treeFiles,
          fullPath: action.fullPath,
          token: action.token,
          isLoading: false,
      }
      case changeFilesType:     
        return {
          ...state,
          treeFiles: action.treeFiles,
          isLoading: false,
        }      
      default:
        return state;
    }
    
};