import React, { Component } from "react";
import {withRouter} from "react-router-dom";
import { bindActionCreators } from "redux";
import { actionOrderCreators } from "../../store/Order";
import {withStyles} from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { connect } from "react-redux";
// import Card from "@material-ui/core/Card";
// import CardContent from "@material-ui/core/CardContent";
// import CardMedia from "@material-ui/core/CardMedia";
// import Button from "@material-ui/core/Button";
// import Input from "@material-ui/core/Input";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import AddShoppingCard from "@material-ui/icons/AddShoppingCart";
import Typography from "@material-ui/core/Typography";


const styles = theme => ({

});

class Confirm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    };
    render() {
        return (
            <div>
                <Typography variant="subtitle1" color="textSecondary">
                    Confirm order
                </Typography>
            </div>
        );
    }
}

export default withWidth() (withRouter( withStyles(styles)(
    connect(    
        state => state.orderRequest,
        dispatch => {
            return {
               actions: bindActionCreators(Object.assign({}, actionOrderCreators), dispatch)
        }}
    )(Confirm)
)));

