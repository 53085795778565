import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import clsx from "clsx";
import TableCell from "@material-ui/core/TableCell";
import { AutoSizer, Column, Table } from "react-virtualized";

import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import { OidcUserManager } from "../../helpers/OidcSettings";


// import withWidth, { isWidthUp } from "@material-ui/core/withWidth";
import withWidth from "@material-ui/core/withWidth";
// import moment from "moment";
// import "moment/locale/ru";
// import "moment/locale/uk";

import axios from "axios";

const styles = theme => ({
    root: {
        flexGrow: 1,     
        width: "100%",   
        padding: theme.spacing(1),
    },
    progress: {
        height: 2,
        position: "absolute",
        top: 0,
        left: 0,
        right:0,
    },  
    linearColorPrimary: {
        backgroundColor: "#abdcda",
    },
    linearBarColorPrimary: {
        backgroundColor: "#d20000",
    },    
    paper: {
        padding: theme.spacing(2),
    },
    
    papertab: {
        marginTop: theme.spacing(1),
        overflowX: "auto"
    },
    tabpanel: {
        height: 585,        
    },    
    flexContainer: {
        display: "flex",
        alignItems: "center",
        boxSizing: "border-box",
        // marginRight: 0
    },
    table: {        
    },
    tableRow: {
        cursor: "pointer",        
    },
    tableRowHover: {
        "&:hover": {
          backgroundColor: theme.palette.grey[200],
        },
      },
    tableCell: {
        flex: 1,        
      },
    noClick: {
        cursor: "initial",
      },    
});

function ReportColumns(){
    const columns = [
        {label: "UserName", dataKey: "Client", width: 390},
        {label: "Level", dataKey: "УровеньКлиента", width: 70 },
        {label: "RegNumber", dataKey: "НомерКонсультанта", width: 90 }, 
        {label: "ViewClient", dataKey: "ВидКлиента", width: 130},
        
        {label: "Phone", dataKey: "Телефон", width: 130},
        {label: "Email", dataKey: "ЭлАдрес", width: 250},

        {label: "MentorNumber", dataKey: "НомерНаставника", width: 90},
        {label: "Mentor", dataKey: "Наставник", width: 380}
    ];

    return columns;
}

const convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item) => {
      return {
        ...obj,
        [item[key]]: item,
      };
    }, initialValue);
  }

function Reports(){
    return [
        {title: "Н/аН_Т", name: "N_AN_T_NewCurrentPeriodConsultantNonActive", data: null},
        {title: "Н/аН", name: "N_AN_NewLastClosedPeriodConsultantNonActive", data: null},
        {title: "АЗНТ", name: "AZNT_NewCurrentPeriodActiveConsultant", data: null},
        {title: "АНТ", name: "ANT_NewOtherPeriodActiveConsultant", data: null},
        {title: "АТ", name: "AT_ActiveRegistered", data: null},
        {title: "ПТ", name: "АpplicantForTermination", data: null},
        {title: "Н/аН3+", name: "N_AN3_NewMoreThan3PeriodConsultantNonActive", data: null}, 
        {title: "Н/а8", name: "N_A8", data: null},
        {title: "Н/а1", name: "N_A1", data: null},
        {title: "Н/а2", name: "N_A2", data: null},
        {title: "Н/а3", name: "N_A3", data: null},
        {title: "Н/а4", name: "N_A4", data: null},
        // {title: "AT_ActiveConsultant", name: "AT_ActiveConsultant", data: null},        
    ];    
}

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <Typography
        component="div"
        role="tabpanel"
        hidden={value !== index}
        id={`scrollable-prevent-tabpanel-${index}`}
        aria-labelledby={`scrollable-prevent-tab-${index}`}
        {...other}
      >
        {value === index && <div style={{paddingTop: 10, minHeight: 250}}>{children}</div>}
      </Typography>
    );
}

class MuiVirtualizedTable extends React.PureComponent {
    static defaultProps = {
      headerHeight: 48,
      rowHeight: 48,
    };
  
    getRowClassName = ({ index }) => {
      const { classes, onRowClick } = this.props;
  
      return clsx(classes.tableRow, classes.flexContainer, {
        [classes.tableRowHover]: index !== -1 && onRowClick != null,
      });
    };
  
    cellRenderer = ({ cellData, columnIndex }) => {
      const { columns, classes, rowHeight, onRowClick } = this.props;
      return (
        <TableCell
          component="div"
          className={clsx(classes.tableCell, classes.flexContainer, {
            [classes.noClick]: onRowClick == null,
          })}
          variant="body"
          style={{ height: rowHeight }}
          align={(columnIndex != null && columns[columnIndex].numeric) || false ? "right" : "left"}
        >
          {cellData}
        </TableCell>
      );
    };
  
    headerRenderer = ({ label, columnIndex }) => {
      const { headerHeight, columns, classes, t } = this.props;
      
      return (
        <TableCell
          component="div"
          className={clsx(classes.tableCell, classes.flexContainer, classes.noClick)}
          variant="head"
          style={{ height: headerHeight }}
          align={columns[columnIndex].numeric || false ? "right" : "left"}
        >
          <span>{t(`reports.activity.columns.${label}`)}</span>
        </TableCell>
      );
    };
  
    render() {
      const { classes, columns, rowHeight, headerHeight, ...tableProps } = this.props;
      return (
        <AutoSizer>
          {({ height, width }) => (
            <Table
              height={height}
              width={width}
              rowHeight={rowHeight}
              gridStyle={{
                direction: "inherit",
              }}
              headerHeight={headerHeight}
              className={classes.table}
              {...tableProps}
              rowClassName={this.getRowClassName}
            >
              {columns.map(({ dataKey, ...other }, index) => {
                return (
                  <Column
                    key={dataKey}
                    headerRenderer={(headerProps) =>
                      this.headerRenderer({
                        ...headerProps,
                        columnIndex: index,
                      })
                    }
                    className={classes.flexContainer}
                    cellRenderer={this.cellRenderer}
                    dataKey={dataKey}
                    {...other}
                  />
                );
              })}
            </Table>
          )}
        </AutoSizer>
      );
    }
}

MuiVirtualizedTable.propTypes = {
    classes: PropTypes.object.isRequired,
    columns: PropTypes.arrayOf(
      PropTypes.shape({
        dataKey: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        numeric: PropTypes.bool,
        width: PropTypes.number.isRequired,
      }),
    ).isRequired,
    headerHeight: PropTypes.number,
    onRowClick: PropTypes.func,
    rowHeight: PropTypes.number,
};

const VirtualizedTable = withTranslation()(withStyles(styles)(MuiVirtualizedTable));

class Activity extends Component {
    constructor(props) {
        super(props);
        const reports = Reports();
        var reportsObj = convertArrayToObject(reports, "name");

        // const { t } = props; 
        const columns = ReportColumns();
        
        // for (let index = 0; index < columns.length; index++) {
        //     const col = columns[index];
        //     col.label = t(`reports.activity.columns.${col.label}`);    
        // }
        
        this.state = {            
            ...reportsObj,
            tabIndex: 0,
            reports: reports,
            columns: columns,
            isLoading: false,
        };    
    }

    componentDidMount() {
        const { reports } = this.state;        
        this.loadData(reports[0]);
    }

    componentWillUnmount() {        
    }

    loadData = async (report) => {
        const self = this;
        const userManager = await OidcUserManager();

        userManager.getUser().then(user => {
            let token = null;

            if (user != null) {
                token =  `Bearer ${user.access_token}`;                
                this.setState({isLoading: true});
                const URL =  `/api/v1.0/reports/activity/${report.name}`;
                // console.log("URL", URL);
                axios.get(URL,  
                    { headers: { Authorization: token } }
                )                
                .then(function(response){
                    // console.log(response.data);                    
                    self.setState({ [report.name]: {name: report.name, title: report.title, data: response.data }, isLoading: false });                    
                })
                .catch((error) => {        
                    this.setState({isLoading: false});
                    console.log(error);
                });               
            }
        });
    };

    handleChangeTabIndex = (event, value) => {
        const { reports } = this.state;
        const report = reports[value];

        this.setState({tabIndex: value});
        
        // console.log(report);
        const data = this.state[report.name].data;
        if(data === null){
            this.loadData(report);
        }

    };

    reportTable = (data) => {
        const { t } = this.props; 
        const {  columns } = this.state;
        return(
            <React.Fragment>            
            {
                data ? 
                <React.Fragment>
                  <Typography align="center" variant="h6" gutterBottom>
                    { `${data.Adviser.RegistrationCode} ${data.Adviser.Name}`}
                  </Typography>
                  
                  <div style={{ overflowX: "auto" }}>
                    <div style={{ height: 455, minWidth: 1200 }}>
                        <VirtualizedTable 
                            rowCount={data.ConsultantsActivity.length}
                            columns={columns} 
                            rowGetter={({ index }) => data.ConsultantsActivity[index]}
                        />                          
                    </div> 
                  </div>
                </React.Fragment>
                :
                <Grid
                   container
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs={12}  sm={10}>
                    <Typography style={{paddingTop: "48px"}} color="textSecondary" variant="h5" align="center" gutterBottom>
                    <p><LinearProgress /></p>
                    {t("common.Loading")}...
                  </Typography>
                  </Grid>
                </Grid>
                
            }
            
            {/* { data && <Typography style={{paddingLeft: "24px", paddingTop: "16px"}}>{t("reports.activity.rows")}: {data.ConsultantsActivity.length}</Typography>} */}
            </React.Fragment>
        );
    };

    UnderConstraction = () => {
        return (
            <Typography variant="h4" align="center">Under construction</Typography>
        );
    };
    
    reportData = (report) => {
        return this.reportTable(this.state[report.name].data);            
    }

    render() {
        const { classes, t } = this.props; 
        const { tabIndex, reports, isLoading } = this.state;

        return (
            <div className={classes.root}>
                { isLoading && 
                    <LinearProgress className={classes.progress} 
                    classes={{
                        colorPrimary: classes.linearColorPrimary,
                        barColorPrimary: classes.linearBarColorPrimary,
                    }}
                />
                }
                                
                <Grid container direction="row" spacing={2} justify="flex-start" alignItems="center" >
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={1}>
                            <Typography variant="h6" align="center">
                                {t("reports.activity.title")}
                            </Typography>                            
                        </Paper>
                    </Grid>
                </Grid>
                 
                <Paper className={classes.papertab}  elevation={1}>
                    <Tabs 
                        value={tabIndex} 
                        onChange={this.handleChangeTabIndex} 
                        aria-label="activity-tabs"
                        indicatorColor="primary"
                        textColor="primary"                                
                        variant="scrollable"
                        scrollButtons="on"                        
                        >
                        {
                        reports.map((report, index) => {
                            return (                                                
                                <Tab color="inherit" key={index} 
                                    // icon={this.getIcon(item.icon)} 
                                    // label={isWidthUp("sm", this.props.width) ? item.title :  null } 
                                    label={t(`reports.activity.tabs.${report.name}.title`)}
                                />  
                            );
                            })
                        }                                                                
                    </Tabs>
                </Paper>
                
                {
                reports.map((report, index) => {
                    return (                                                
                        <TabPanel key={index} value={tabIndex} index={index}>
                            <Paper className={classes.tabpanel} elevation={1}> 
                              <Typography align="center" variang="caption" display="block">
                                  {t(`reports.activity.tabs.${report.name}.title`)}<br />
                                  <small>{t(`reports.activity.tabs.${report.name}.description`)}</small>
                              </Typography>
                              
                              {/* Report table */}
                              {this.reportData(report)}                                
                            </Paper>  
                        </TabPanel>                                                                                                                                        
                    );
                    })
                }
            </div>            
        );
    }
}


export default  withTranslation()(withWidth() (withStyles(styles)((Activity))));

