import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
// import classNames from 'classnames';
import { withStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom"
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";

import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowForward from "@material-ui/icons/ArrowForward";

import { actionCreators } from "../store/WeatherForecasts";

const styles = theme => ({
  root: {
    width: "100%",
    marginTop: theme.spacing(1) * 2,
    overflowX: "auto",
  },
  progress: {
    margin: theme.spacing(1) * 2,
  },
  button: {
    margin: theme.spacing(1)    
  },
  table: {
    Width: "100%",
  },
});
class FetchData extends Component {
  constructor(props) {        
    super(props);
    this.state = {};
    const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
    this.props.requestWeatherForecasts(startDateIndex);
}



  // componentWillReceiveProps(nextProps) {
  //   // This method runs when incoming props (e.g., route params) change
  //   const startDateIndex = parseInt(nextProps.match.params.startDateIndex, 10) || 0;
  //   this.props.requestWeatherForecasts(startDateIndex);
  // }

  render() {    
    return (
      <div>
        <h1>Random weather forecast!</h1>
        <p>This component demonstrates fetching data from the server and working with URL parameters.</p>
        {renderForecastsTable(this.props)}
        {renderPagination(this.props)}
      </div>
    );
  }
  
}

function renderForecastsTable(props) { 
  const { classes } = props;

  console.log(props.forecasts);

  return (
    <Paper className={classes.root}>    
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
            <TableCell>Date</TableCell>
            <TableCell align="right">Temp. (C)</TableCell>
            <TableCell align="right">Temp. (F)</TableCell>
            <TableCell >Summary</TableCell>            
        </TableRow>
      </TableHead>      
      <TableBody>
          {props.forecasts.map(forecast => {
            return (
              <TableRow key={forecast.id}>                
                <TableCell >{forecast.dateFormatted}</TableCell>
                <TableCell align="right">{forecast.temperatureC}</TableCell>
                <TableCell align="right">{forecast.temperatureF}</TableCell>
                <TableCell >{forecast.summary}</TableCell>
              </TableRow>
            );                   
          })}
      </TableBody>        
    </Table>
    </Paper>     
  );
}

function renderPagination(props) {
  const { classes } = props;
  const prevStartDateIndex = (props.startDateIndex || 0) - 5;
  const nextStartDateIndex = (props.startDateIndex || 0) + 5;

  return ( <p className='clearfix text-center'>
    
    <Button mini className={classes.button}  component={Link} to={`/fetchdata/${prevStartDateIndex}`}>
      <ArrowBack />      
    </Button>
    <Button mini className={classes.button} component={Link} to={`/fetchdata/${nextStartDateIndex}`}>        
      <ArrowForward />
    </Button>


    {/* <Link className='btn btn-default pull-left' to={`/fetchdata/${prevStartDateIndex}`}>Previous</Link>
    <Link className='btn btn-default pull-right' to={`/fetchdata/${nextStartDateIndex}`}>Next</Link> */}
    {props.isLoading ? <span className={classes.progress}>Loading...</span> : []}
  </p>
  );
}

export default withStyles(styles) (connect(
  state => state.weatherForecasts,
  dispatch => bindActionCreators(actionCreators, dispatch)
)(FetchData));

