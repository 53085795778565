import React from "react";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Rating from "@material-ui/lab/Rating";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withTranslation } from "react-i18next";

const styles = theme => ({
  root: {            
      flexGrow: 1,
      padding: theme.spacing(1),
  },
});

class EditReviw extends React.Component {  
  constructor(props){
    super(props);
    console.log(props.review)
    this.state={
      // open: props.open,
      review: {...props.review}      
    }
  }

  static getDerivedStateFromProps(props, state){    
    if(props.open !== state.open){        
      return {open: props.open, review: props.review};
    }
    else return null;
  }         

  handleClose = () => {
    this.props.onClose(false);
  }

  handleChange = (e) => {
    const review = {...this.state.review};
    review[e.target.name] = e.target.value;
    this.setState({ review: review});
  }

  handleSetRating = (e, value) => {
    const review = {...this.state.review};
    review.Rating = value;
    this.setState({ review: review});
  };

  handleSetBest = () => {
    const review = {...this.state.review};
    review.Best = !review.Best;
    this.setState({ review: review});
  }

  handleSave = () => {    
    this.props.onSave(this.state.review);
  };

  render() {
    const { open, classes, t} = this.props;
    const { review } = this.state;

    return (
      <React.Fragment>
        { review &&
          <Dialog open={open} fullWidth maxWidth={"md"} onClose={this.handleClose} aria-labelledby="form-dialog-review">
            <DialogTitle id="form-dialog-review">
              {t("reviews.editReview")} Id: {review.Id}, {review.ProductCode}, {review.ProductName} 
            </DialogTitle>
            <DialogContent>
              
              {/* <DialogContentText>                    
                  Review about 
              </DialogContentText> */}
          
              <Grid container direction="row">
                  <Grid item xs={12}>
                      <Box mb={2} className={classes.reviewbox}>                                                                                                
                          <Grid container direction="row" spacing={2}>
                              <Grid item xs={6}>
                              <Box display="flex" alignItems="center" mb={1}>                                                
                              <Box width="100%" mr={1}>
                                  <Rating 
                                      name="rating-controlled"
                                      value={review.Rating}
                                      onChange={this.handleSetRating}
                                  />
                              </Box>                                            
                          </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <FormControlLabel
                                  control={<Checkbox checked={review.Best} onChange={this.handleSetBest} name="Best" />}
                                  label={t("reviews.theBestReview")}
                                  color="primary"
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                  <TextField                                            
                                      fullWidth
                                      id="Advantage"
                                      name="Advantage"
                                      label={t("reviews.advantage")}
                                      value={review.Advantage}
                                      multiline
                                      rows={3}
                                      variant="outlined"
                                      onChange={this.handleChange}
                                  />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                  <TextField                                                        
                                      fullWidth
                                      id="limitations"
                                      name="Limitations"
                                      label={t("reviews.limitations")}
                                      value={review.Limitations}
                                      multiline
                                      rows={3}
                                      variant="outlined"
                                      onChange={this.handleChange}
                                  />
                              </Grid>
                              <Grid item xs={12}>
                                  <TextField                                                        
                                      fullWidth
                                      id="text"
                                      name="Text"
                                      label= {t("reviews.review")}
                                      value={review.Text}
                                      multiline
                                      rows={5}
                                      variant="outlined"
                                      onChange={this.handleChange}
                                  />
                              </Grid>
                          </Grid>
                          
                      </Box>
                  </Grid>
              </Grid>                            
          </DialogContent>

          <DialogActions>
          <Button onClick={this.handleClose} color="default">
            {t("buttons.Cancel")}
          </Button>
          <Button variant="contained" onClick={this.handleSave} color="primary">
              {t("buttons.Save")}
          </Button>
          </DialogActions>
      </Dialog>
        }
      </React.Fragment>
    );
  }

}

export default  withTranslation()(withWidth()(withStyles(styles, { withTheme: true })(EditReviw)));
