/* eslint-disable no-undef */
/* /src/utils/authConst.js */

export const IDENTITY_CONFIG = {

    // "authority": "https://auth.jerelia.com",
    authority: process.env.REACT_APP_AUTHORITY, //(string): The URL of the OIDC provider.
    "client_id": process.env.REACT_APP_CLIENT_ID,
    "redirect_uri": process.env.REACT_APP_REDIRECT_URL,
    "popup_redirect_uri": process.env.REACT_APP_REDIRECT_URL,
    "post_logout_redirect_uri": process.env.REACT_APP_POST_LOGOUT_REDIRECT_URL,
    "silent_redirect_uri": process.env.REACT_APP_SILENT_REDIRECT_URL,
    "response_type": process.env.REACT_APP_RESPONSE_TYPE,
    "scope": process.env.REACT_APP_SCOPE,
    "automaticSilentRenew": true,
    "monitorSession": true,
    "loadUserInfo": true

    
    // client_id: process.env.REACT_APP_CLIENT_ID, //(string): Your client application's identifier as registered with the OIDC provider.
    // redirect_uri: process.env.REACT_APP_REDIRECT_URL, //The URI of your client application to receive a response from the OIDC provider.
    // login: process.env.REACT_APP_AUTHORITY + "login",
    // automaticSilentRenew: true, //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
    // loadUserInfo: true, //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
    // silent_redirect_uri: process.env.REACT_APP_REDIRECT_URL, //(string): The URL for the page containing the code handling the silent renew.
    // post_logout_redirect_uri: process.env.REACT_APP_POST_LOGOUT_REDIRECT_URL, // (string): The OIDC post-logout redirect URI.
    // audience: "https://jerelia.com", //is there a way to specific the audience when making the jwt
    // responseType: process.env.REACT_APP_RESPONSE_TYPE, //(string, default: 'id_token'): The type of response desired from the OIDC provider.
    // grantType: "password",
    // scope: "api1 openid roles email jerelia_identity_admin_api profile", //(string, default: 'openid'): The scope being requested from the OIDC provider.
    // webAuthResponseType: process.env.REACT_APP_RESPONSE_TYPE
};


export const METADATA_OIDC = {
    issuer: "https://auth.jerelia.com",
    jwks_uri: process.env.REACT_APP_AUTHORITY + "/.well-known/openid-configuration/jwks",
    authorization_endpoint: process.env.REACT_APP_AUTHORITY + "/connect/authorize",
    token_endpoint: process.env.REACT_APP_AUTHORITY + "/connect/token",
    userinfo_endpoint: process.env.REACT_APP_AUTHORITY + "/connect/userinfo",
    end_session_endpoint: process.env.REACT_APP_AUTHORITY + "/connect/endsession",
    check_session_iframe: process.env.REACT_APP_AUTHORITY + "/connect/checksession",
    revocation_endpoint: process.env.REACT_APP_AUTHORITY + "/connect/revocation",
    introspection_endpoint: process.env.REACT_APP_AUTHORITY + "/connect/introspect"
};