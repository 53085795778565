import React from "react";
import PropTypes from "prop-types";
import Paper from "@material-ui/core/Paper";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import BigCalendar from "react-big-calendar";
// import moment from 'moment/min/moment-with-locales';


import "moment/locale/ru";
import "moment/locale/uk";

import events from "./events";

import "react-big-calendar/lib/css/react-big-calendar.css";

const styles = theme => ({
    root: {    
        margin: theme.spacing(1),
        padding: theme.spacing(1),
      },
});

moment.locale("en");
const localizer = BigCalendar.momentLocalizer(moment);
let allViews = Object.keys(BigCalendar.Views).map(k => BigCalendar.Views[k]);

class Calendar extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            events: events,
            culture: "ru",
        }

        // this.moveEvent = this.moveEvent.bind(this)
        // this.newEvent = this.newEvent.bind(this)
    }

    render(){
        const { classes } = this.props;
        // const cultures = ['en-US','uk-UA','ru-RU'];
        return(
            <Paper className={classes.root} >            
                <div style={{ height: 500 }}>
                    <BigCalendar                    
                        views={allViews}                    
                        culture={this.state.culture}                    
                        events={this.state.events}
                        localizer={localizer} 
                    />
                </div>
            </Paper>
        );
    }
}

Calendar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Calendar);
  