import { OidcUserManager } from "../helpers/OidcSettings";
import axios from "axios";

const requestGoodsType = "REQUEST_GOODS";
const searchGoodsType = "SEARCH_GOODS";
const reciveGoodsGrpsType = "RECIVE_GOODS_GRPS";
const reciveGoodsItemsType = "RECIVE_GOODS_ITEMS";
const changeTreeNodeType = "CHANGE_TREENODE";
const setSelectedGoodsType = "SET_SELECTED_GOODS_TYPE";
const clearSelectedGoodsType = "CLEAR_SELECTED_GOODS_TYPE";

const initialState = {
  rootId: "00000000-0000-0000-0000-000000000000",  
  parentGrpId: "00000000-0000-0000-0000-000000000000",
  parentItemsId: "00000000-0000-0000-0000-000000000000",
  treeData: [],
  goods: [],
  search: "",
  field: "",
  pi: 1,
  ps: 4096,
  selected: [],
};

async function LoadData(url, token){
  return await axios.get(url, { headers: { Authorization: token } });        
};

export const actionGoods = {

    loadNodes: (node, path) => async (dispatch, getState) => {
      // console.log(node, path);  
      const url = `/api/v1.0/Goods/GetFoldersByParentId/${node.id}`;       
      
      let token = null;
      const userManager = await OidcUserManager();
      userManager.getUser().then(function(user) {        
      if (user != null) {
        token = `Bearer ${user.access_token}`;
      }
      const treeData = getState().goodsItemsRequest.treeData;      

      (async () => {
        const response = await LoadData(url, token);
        let children = [];
                        
        for (let i = 0; i < response.data.length; i++) {
          const element = response.data[i];
          if(element.HasChilds){
            children.push({id: element.Id, title: element.Name, IsFolder: element.IsFolder, children: [{title: "Loading...", IsFolder: true}], expanded: false });
          }
          else{
            children.push({id: element.Id, title: element.Name, IsFolder: element.IsFolder, children: [], expanded: false });
          }                  
        }
        
        let parent = null;
        let previdx = 0;
        for (let i = 0; i < path.length; i++) {
          const idx = path[i];                      
          previdx = idx - previdx;                              
          parent = parent === null ? treeData[previdx] : 
            (parent.children !== null ? parent.children[previdx-1] : null);
        }  
        
        if(parent !== null){
          if(parent.children !== null){
            parent.children = children;
          }          
        }
        
        console.log("TREE DATA", treeData);
        
        dispatch({ type: changeTreeNodeType,  treeData: [...treeData] });  

      })();

    });
    },

    changeTreeNode: (treeData) => async (dispatch) => {      
      dispatch({ type: changeTreeNodeType,  treeData: treeData });
    },

    visibilityToggle: (args) => async (dispatch) => {      
      
      if(!args.expanded){
        return;
      }
      const url = `/api/v1.0/Goods/GetFoldersByParentId/${args.node.id}`;       
      
      let token = null;
      const userManager = await OidcUserManager();
      userManager.getUser().then(function(user) {
        
        if (user != null) {
          token = `Bearer ${user.access_token}`;
        }

        (async () => {

          const response = await LoadData(url, token);
          let children = [];
          if(response.data){
            for (let i = 0; i < response.data.length; i++) {
              const element = response.data[i];
              if(element.HasChilds){
                children.push({id: element.Id, title: element.Name, IsFolder: element.IsFolder, children: [{title: "Loading...", IsFolder: true}], expanded: false });
              }
              else{
                children.push({id: element.Id, title: element.Name, IsFolder: element.IsFolder, children: [], expanded: false });
              }                  
            }
          }

          for (let i = 0; i < args.treeData.length; i++) {
            const element =  args.treeData[i];
            if(element.id === args.node.id)
            {
              element.children = children;
              break;
            }
          }          
          dispatch({ type: changeTreeNodeType,  treeData: args.treeData  });
          
        })();
                
      });      
    },

    searchGoods: (search, field) => async (dispatch, getState) =>{
      const goodsItemsRequest = getState().goodsItemsRequest;
      const _search = goodsItemsRequest.search;
      const _field = goodsItemsRequest.field;

      if(_search === search && _field === field ){
        return;
      }
      dispatch({ type: requestGoodsType });
      
      let token = null;
      const userManager = await OidcUserManager();
      userManager.getUser().then(function (user) {
      
        const url = `/api/v1.0/Goods/GetChildsByParentId/${goodsItemsRequest.rootId}/${goodsItemsRequest.pi}/${goodsItemsRequest.ps}/${search}/${field}`; 
          
        axios.get(url,
          { 
            headers: { Authorization: token }
          }
        )
        .then(function(response){  
          dispatch({ type: searchGoodsType, goods: response.data  });
        });

      });

    },

    goodsGrpsRequest: (pid, isGrp) => async (dispatch, getState) => {       
        const parentGrpId = getState().goodsItemsRequest.parentGrpId;      
        if (parentGrpId === pid ) {
          return;
        }        

        dispatch({ type: requestGoodsType });

        let token = null;
        const userManager = await OidcUserManager();

        userManager.getUser().then(function (user) {
          
          if(user != null){      
            token =  `Bearer ${user.access_token}`;        
          }                  

          const part = isGrp ? "GetFoldersByParentId":"GetChildsByParentId";
          const url = `/api/v1.0/Goods/${part}/${pid}`; 
          
          axios.get(url,
            { 
              headers: { Authorization: token }
            }
          )
          .then(function(response){
            
            
            if(isGrp){
              let treeData = [];

              if(response.data){
                for (let i = 0; i < response.data.length; i++) {
                  const element = response.data[i];
                  if(element.HasChilds){
                    treeData.push({id: element.Id, title: element.Name, IsFolder: element.IsFolder, children: [{title: "", IsFolder: true}],expanded: false });
                  }
                  else{
                    treeData.push({id: element.Id, title: element.Name, IsFolder: element.IsFolder, children: [], expanded: false });
                  }                  
                }
              }           
              dispatch({ type: reciveGoodsGrpsType, parentGrpId: pid, treeData: treeData });
            }
            else{           
              dispatch({ type: reciveGoodsItemsType, parentItemsId: pid, goods: response.data  });
            }  
          })
          .catch((error) => {        
            console.log(error);  
            dispatch({ type: reciveGoodsItemsType, initialState  });      
          });

        });  
    },

    goodsSelected: (selected) => async (dispatch) => {
      dispatch({ type: setSelectedGoodsType, selected });
    },
    
    getSelectedGoods: () => async (dispatch, getState) => {
      return getState().goodsItemsRequest.selected;
    },

    clearSelected: () => async (dispatch) => {
      dispatch({ type: clearSelectedGoodsType, selected: [] });
    },
};

export const reducer = (state, action) => {
    state = state || initialState;

    if (action.type === requestGoodsType ) {
      return {
        ...state,        
        isLoading: true
      };
    }

    if (action.type === changeTreeNodeType ) {      
      return {
        ...state,                
        treeData: action.treeData, 
      };
    }

    if (action.type === reciveGoodsGrpsType ) {      
      return {
        ...state,
        isLoading: false,
        parentGrpId: action.parentGrpId,
        treeData: action.treeData,        
      };
    }

    if (action.type === reciveGoodsItemsType ) {
      // console.log(action.goods);
      return {
        ...state,
        isLoading: false,
        parentItemsId: action.parentItemspId,
        goods: action.goods,        
      };
    }

    if (action.type === searchGoodsType ) {
      // console.log(action.goods);
      return {
        ...state,
        isLoading: false,        
        goods: action.goods,        
      };
    }
    
    if (action.type === setSelectedGoodsType || action.type === clearSelectedGoodsType) {
      return {
        ...state,
        selected: action.selected        
      };
    }


  return state;
};