import React from "react";


import { bindActionCreators } from "redux";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";

import classNames from "classnames";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

import { connect } from "react-redux";
import { actionCreators } from "../store/Auth";


const styles = theme => ({
    root: {
        overflow: "hidden",        
      },
      wrapper: {
        maxWidth: "100%",
      },
      paper: {
        margin: theme.spacing(1),
        padding: theme.spacing(1) * 2,
      },

    margin: {
            margin: theme.spacing(1),
        },

    textField: {
        flexBasis: 440,
    },
    form: {
        display: "flex",
        alignItems: "baseline",
        justifyContent: "space-evenly"
      },
      button: {
        margin: theme.spacing(1),
      }
});


class AuthForm extends React.Component {  
  constructor(props) {
    super(props);
    this.state = { 
      email:"",    
      password: "",        
      showPassword: false,
    };
  }

  handleChange = prop => event => {      
    this.setState({ [prop]: event.target.value });
  };

  handleClickShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  submitHandler = ( event ) => {
    event.preventDefault();
    this.props.onAuth(this.state.email, this.state.password );
    //this.props.onAuth( this.state.controls.email.value, this.state.controls.password.value, this.state.isSignup );
}

loginHandler = ( event ) => {  
  this.props.onLogin();
}

logoutHandler = ( event ) => {  
  this.props.onLogout();
}

getUserInfoHandler = ( event ) => {  
  this.props.getUserInfo();
}



render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
            <Paper className={classes.paper}> 
            <form className={classes.form} noValidate autoComplete="off" onSubmit={this.submitHandler}>   
                <Grid container spacing={8}>
                    <TextField
                    id="filled-email-input"
                    label="Email"
                    className={classNames(classes.margin, classes.textField)}
                    type="email"
                    name="email"
                    autoComplete="email"          
                    variant="outlined"
                    value={this.state.email}
                    onChange={this.handleChange("email")}
                    />

                    <TextField
                    id="outlined-adornment-password"
                    className={classNames(classes.margin, classes.textField)}
                    variant="outlined"
                    type={this.state.showPassword ? "text" : "password"}
                    label="Password"
                    value={this.state.password}
                    onChange={this.handleChange("password")}
                    InputProps={{
                        endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                            aria-label="Toggle password visibility"
                            onClick={this.handleClickShowPassword}
                            >
                            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        </InputAdornment>
                        ),
                    }}
                    />

                    <Button className={classes.button} type='submit' color="secondary">
                      Token
                    </Button>
                    <Button className={classes.button} onClick={this.getUserInfoHandler} >
                      User Info
                    </Button>
                    <Button className={classes.button} onClick={this.loginHandler} >
                      Login
                    </Button>
                    <Button className={classes.button} onClick={this.logoutHandler} >
                      Logout
                    </Button>
                    
                  </Grid>
            </form>        
            </Paper>        
        </div>
      </div>
    );
  }
}

AuthForm.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(connect(
  state => state.auth,
  dispatch => bindActionCreators(actionCreators, dispatch)
)(AuthForm));
