import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import LinearProgress from "@material-ui/core/LinearProgress";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import { OidcUserManager } from "../../helpers/OidcSettings";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Icon from "@material-ui/core/Icon";
// import DeleteOutlineIcon from "@material-ui/icons/DeleteOutlineIcon";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import gray from "@material-ui/core/colors/grey";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/uk";

import axios from "axios";

const styles = theme => ({
    root: {
        flexGrow: 1,        
        padding: theme.spacing(1),
    },
    progress: {
        height: 2,
        position: "absolute",
        top: 0,
        left: 0,
        right:0,
      },  
      linearColorPrimary: {
        backgroundColor: "#abdcda",
      },
      linearBarColorPrimary: {
        backgroundColor: "#d20000",
      },
    
    paper: {
        padding: theme.spacing(2),
    },
    info: {
        padding: theme.spacing(1),
        height: "auto",
        [theme.breakpoints.down("sm")]: {
            height: 100,
          },
    },
    divider: {        
        marginBottom:theme.spacing(2),
    },
    customer: {
        textTransform: "capitalize",
    },
    large: {
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    media: {
        height: 183,
        // backgroundSize: "contain",
    },
    value: {
        fontWeight: "bold",
    },
    table: {
        minWidth: 500,
    },
    bold: {
        fontWeight: "500",
    },
    total: {
        fontWeight: "500",
        backgroundColor: gray[200],
    },
    step: {
        marginRight: theme.spacing(1),        
      },  
    fixcell: {
        backgroundColor: gray[100],
    },
});


class Terminated extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            keys: [],
            isLoading: false,
        };

        moment.locale("uk");
    }

    componentDidMount() {
        this.loadDashBoard();        
    }

    componentWillUnmount() {        
    }

    loadDashBoard = async () => {
        const self = this;
        const userManager = await OidcUserManager();

        userManager.getUser().then(user => {
            let token = null;

            if (user != null) {
                token =  `Bearer ${user.access_token}`;                
                this.setState({isLoading: true});
                const URL =  "/api/v1.0/reports/terminated";
                axios.get(URL,  
                    { headers: { Authorization: token } }
                )                
                .then(function(response){
                    const keys = [];
                                        
                    self.setState({ data: response.data, isLoading: false, keys: keys });
                    
                    // console.log("Terminated data", response.data); 
                })
                .catch((error) => {        
                    this.setState({isLoading: false});
                    console.log(error);
                });                
            }
        });
    };


    render() {
        const { classes, t } = this.props; 
        const { data, isLoading } = this.state;

        return (
            <div className={classes.root}>
                { isLoading && 
                    <LinearProgress className={classes.progress} 
                    classes={{
                        colorPrimary: classes.linearColorPrimary,
                        barColorPrimary: classes.linearBarColorPrimary,
                    }}
                />
                }

                <Grid container direction="row" spacing={2} justify="flex-start" alignItems="center" >
                    <Grid item xs={12}>
                        <Paper className={classes.paper} elevation={1}>
                            <Typography variant="h6" align="center">
                                {t("reports.terminated.title")}
                            </Typography>
                            { data && 
                            <Typography align="center">
                                { data ? `${data.Adviser.RegistrationCode} ${data.Adviser.Name}` : null }
                            </Typography>
                            }
                        </Paper>
                    </Grid>
                </Grid>
                { 
                
                data && (
                <Grid container spacing={2} direction="row"  >                                             
                    <Grid item xs={12}> 
                    <Accordion defaultExpanded TransitionProps={{ unmountOnExit: true }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="applicantsTermination"
                                id="applicantsTermination-header">
                                    <FormControlLabel
                                    aria-label="Terminated"
                                    control={<Icon className={classes.step}>access_time</Icon>}                
                                    label={ <Typography>{t("reports.terminated.terminatedApplicantsSection")}</Typography>}
                                />                
                            </AccordionSummary>
                            <AccordionDetails>
                                <div style={{overflowX: "auto", width: "100%", display: "grid"}}> 
                                    <TableContainer >
                                        <Table className={classes.table} size="small" aria-label="a dense table">                                            
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{ t("reports.terminated.terminatedSectionTable.FullName") }</TableCell>
                                                <TableCell>{ t("reports.terminated.terminatedSectionTable.RegNum") }</TableCell>
                                                <TableCell> { t("reports.terminated.terminatedSectionTable.PhoneEmail") }</TableCell>
                                                <TableCell> { t("reports.terminated.terminatedSectionTable.RegNumMentor") }</TableCell>
                                                <TableCell> { t("reports.terminated.terminatedSectionTable.FullNameMentor") }</TableCell>                                                                                                
                                                <TableCell> { t("reports.terminated.terminatedSectionTable.PhoneEmailMentor") }</TableCell>
                                                <TableCell> { t("reports.terminated.terminatedSectionTable.Birthday") }</TableCell>
                                                <TableCell> { t("reports.terminated.terminatedSectionTable.Region") }</TableCell>
                                                <TableCell> { t("reports.terminated.terminatedSectionTable.District") }</TableCell>
                                                <TableCell> { t("reports.terminated.terminatedSectionTable.City") }</TableCell>                                                
                                            </TableRow>
                                        </TableHead>
                                        {data.ConsultantsTermination &&                                        
                                            <TableBody>
                                            {
                                                data.ConsultantsTermination.АpplicantsForTermination.map((row) => ( 
                                                    <TableRow key={row.ClientRegNum}>
                                                        <TableCell component="th" scope="row">{row.Client}</TableCell>
                                                        <TableCell>{row.ClientRegNum}</TableCell>
                                                        <TableCell>{row.Phone}<br/>{row.EMail}</TableCell>
                                                        <TableCell>{row.MentorRegNum}</TableCell>
                                                        <TableCell>{row.Mentor}</TableCell>
                                                        <TableCell>{row.MentorPhone}<br/>{row.MentorEMail}</TableCell>
                                                        <TableCell>{moment(row.BirthDate).format("DD-MM-YYYY")}</TableCell>
                                                        <TableCell>{row.Region}</TableCell>
                                                        <TableCell>{row.District}</TableCell>
                                                        <TableCell>{row.City}</TableCell>
                                                    </TableRow>
                                                ))
                                            }
                                            </TableBody>
                                        }
                                        </Table>
                                </TableContainer>
                                </div>
                            </AccordionDetails>
                        </Accordion>

                        <Accordion TransitionProps={{ unmountOnExit: true }}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="terminated"
                                id="terminated-header">
                                    <FormControlLabel
                                    aria-label="Terminated"
                                    control={<Icon className={classes.step}>block</Icon>}                
                                    label={ <Typography>{t("reports.terminated.terminatedSection")}</Typography>}
                                />                
                            </AccordionSummary>
                            <AccordionDetails>
                                <div style={{overflowX: "auto", width: "100%", display: "grid"}}>                                    
                                    <TableContainer >
                                        <Table className={classes.table} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>{ t("reports.terminated.terminatedSectionTable.FullName") }</TableCell>
                                                <TableCell>{ t("reports.terminated.terminatedSectionTable.RegNum") }</TableCell>
                                                <TableCell> { t("reports.terminated.terminatedSectionTable.PhoneEmail") }</TableCell>
                                                <TableCell> { t("reports.terminated.terminatedSectionTable.RegNumMentor") }</TableCell>
                                                <TableCell> { t("reports.terminated.terminatedSectionTable.FullNameMentor") }</TableCell>                                                                                                
                                                <TableCell> { t("reports.terminated.terminatedSectionTable.PhoneEmailMentor") }</TableCell>
                                                <TableCell> { t("reports.terminated.terminatedSectionTable.DateTermination") }</TableCell>
                                                <TableCell> { t("reports.terminated.terminatedSectionTable.PeriodTermination") }</TableCell>
                                                <TableCell> { t("reports.terminated.terminatedSectionTable.Birthday") }</TableCell>
                                                <TableCell> { t("reports.terminated.terminatedSectionTable.Region") }</TableCell>
                                                <TableCell> { t("reports.terminated.terminatedSectionTable.District") }</TableCell>
                                                <TableCell> { t("reports.terminated.terminatedSectionTable.City") }</TableCell>                                                
                                            </TableRow>
                                        </TableHead>
                                        {data.ConsultantsTermination &&
                                        
                                        <TableBody>
                                        {
                                            data.ConsultantsTermination.Terminations.map((row) => ( 
                                            <TableRow key={row.ClientRegNum}>
                                                <TableCell component="th" scope="row">{row.Client}</TableCell>
                                                <TableCell>{row.ClientRegNum}</TableCell>
                                                <TableCell>{row.Phone}<br/>{row.EMail}</TableCell>
                                                <TableCell>{row.MentorRegNum}</TableCell>
                                                <TableCell>{row.Mentor}</TableCell>
                                                <TableCell>{row.MentorPhone}<br/>{row.MentorEMail}</TableCell>
                                                <TableCell>{moment(row.Termination).format("DD-MM-YYYY")}</TableCell>
                                                <TableCell>{row.TerminationPeriod}</TableCell>
                                                <TableCell>{moment(row.BirthDate).format("DD-MM-YYYY")}</TableCell>
                                                <TableCell>{row.Region}</TableCell>
                                                <TableCell>{row.District}</TableCell>
                                                <TableCell>{row.City}</TableCell>
                                            </TableRow> 
                                         ))  
                                        }
                                        </TableBody>
                                        }                                   
                                        </Table> 
                                </TableContainer>
                                </div>
                            </AccordionDetails>
                        </Accordion>



                            
                        
                    </Grid>
                </Grid> 
                )
            }
            </div>            
        );
    }
}


export default  withTranslation()(withWidth() (withStyles(styles)((Terminated))));

